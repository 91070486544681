import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logoColor from "../assets/img/logo-color.png";
import logoWhite from "../assets/img/logo-white.png";
import hamburger from "../assets/img/hamburger.svg";
import cross from "../assets/img/cross.svg";
import LinkButton from "./shared/LinkButton";
import Whatsapp from "../assets/img/icon/Whatsapp";

const menu = [
  {
    title: "Perusahaan",
    url: "/perusahaan",
  },
  {
    title: "Bisnis",
    url: "/bisnis",
  },
  {
    title: "Simulasi KPR",
    url: "https://ppdpp.id/kalkulator-kpr-flpp/",
    newTab: true,
  },
  {
    title: "Kontak",
    url: "/kontak",
  },
];

const Navbar = (props) => {
  const location = useLocation();
  const [active, setActive] = useState("");
  const [offsetY, setOffsetY] = useState(window.scrollY);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setActive(location.pathname);
    const onScroll = (e) => {
      setOffsetY(window.scrollY);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [location.pathname]);

  return (
    <nav
      className={`${props.scroll && offsetY < 650 ? "scroll" : ""} ${
        open ? "scroll-open" : ""
      }`}
    >
      <Link to="/" className="logo">
        <img
          src={props.scroll && offsetY < 650 ? logoWhite : logoColor}
          alt="Logo"
        />
      </Link>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className="hamburger"
      >
        <img src={open ? cross : hamburger} alt="menu" />
      </button>
      <div className={`menu ${open ? "open" : ""}`}>
        {menu.map((data, i) => {
          const activeLink = active.indexOf(data.url) > -1;
          return (
            <div
              key={data.title}
              className={`link ${activeLink ? "active" : ""}`}
            >
              {data.newTab ? (
                <a href={data.url} target="_blank" rel="noreferrer noopener">
                  {data.title}
                </a>
              ) : (
                <Link to={data.url}>{data.title}</Link>
              )}
              <div className={`link-underline ${activeLink ? "active" : ""}`} />
            </div>
          );
        })}
        <div>
          <LinkButton
            icon={<Whatsapp />}
            blank
            href="https://api.whatsapp.com/send?phone=6285888073655&text=Terima%20kasih%20telah%20menghubungi%20Perumahan%20Sativa%20Podomoro.%20Rumah%20Type%2047%2F66.%20Rumah%20Komersil%20Harga%20Subsidi.%0D%0APesan%20anda%20akan%20segera%20kami%20proses%20harap%20sabar%20menunggu%20balasan%20%F0%9F%98%8A%0D%0A%0D%0AKepuasan%20Anda%20Kebahagiaan%20kami%20Terimakasih%20%F0%9F%98%8A%0D%0A%0D%0ASativa%20Podomoro%0D%0AYour%20Living%20Residential%20%F0%9F%8C%BF"
            variant="secondary"
            label="Hubungi Kami"
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
